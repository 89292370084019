.breadcrumbs {
    ul {
        font-size: $small-font-size;
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: .5rem;
    }
}