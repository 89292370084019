// btn
.btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 99px;
	line-height: 26px;
	font-size: $small-font-size;
	font-weight: 700 !important;
	font-family: $font-family-secondary !important;

	&:focus {
		box-shadow: none;
	}

	@include media-breakpoint-up(xl) {
		padding-left: 1.75rem;
		padding-right: 1.75rem;
	}

	// btn-primary
	&.btn-primary {
		color: $white;

		&:hover {
			background-color: $secondary;
			border-color: $secondary;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {
			color: $white;
		}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {
			background-color: $primary;
			border-color: $primary;
		}
	}

	// btn-outline-secondary
	// &.btn-outline-secondary {
	// 	&:hover {}
	// }

	// btn-outline-white
	&.btn-outline-white {
		border: 1px solid $white;
		color: $white;

		&:hover {
			background-color: $white;
			color: $black;
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}

.wishlist-btn {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 10;
	background-color: $white;
	color: $black;
	border-radius: 50%;
	width: 36px;
	height: 36px;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: .2s;

	i {
		line-height: 1;
		font-size: 16px;
		transform: translateY(2px);
	}

	&.active {
		background-color: $primary;
	}

	&:hover {
		background-color: $secondary;
	}

	&:hover,
	&.active {
		color: $white;
	}

	&:active {
		transform: scale(0.9);
	}
}