// main
&.bundle-overview,
&.bundle-category {
	.main {
		padding: 1px 0;
		// background-color: $blue-light;
	}
}

// container
.container {
	&.container-small {
		@include media-breakpoint-up(sm) {
			max-width: 540px;
		}
	}

	&.container-medium {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}
	}

	&.container-large {
		@include media-breakpoint-up(lg) {
			max-width: 960px;
		}
	}

	&.container-intro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-top: 30px;
		text-align: center;
	}
}

.container-fluid {
	max-width: 1920px;

	@include media-breakpoint-up(xl) {
		padding-left: 30px;
		padding-right: 30px;
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
	object-fit: cover;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

.link-slider {
	font-family: $headings-font-family;
	font-weight: 700;
}

// form
.form {
	background-color: $white;
	padding: 15px;
	border-radius: 10px;
	box-shadow: 0 0 4px rgba($black, 0.25);

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

}

// bold
b, strong {
	font-weight: 700;
}

// default-card
.default-card {
	border: none;
	border-radius: 10px;
	box-shadow: 0 0 4px rgba($black, 0.25);

	.card-image {
		overflow: clip visible;

		&-caption {
			width: 65px;
			top: 10px;
			left: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: $white;
			padding: 5px;
			background-color: $orange;
			border-radius: 100%;
			font-size: $font-size-base;
			font-weight: 400;
			aspect-ratio: 1;
		}

		&-label {
			padding: 0;
			font-size: $small-font-size;
		}
	}

	.card-image:has(.card-image-label) {
		+ .card-body {
			padding-top: 2.25rem;
		}
	}

	.card-body {
		padding: 20px;

		.card-caption {
			.card-title {
				@include font-size($h5-font-size);
			}

			.card-subtitle {
				font-family: $font-family-base;
				font-size: $small-font-size;
				font-weight: 300;
			}
		}

		.card-tags {
			display: flex;
			flex-flow: row wrap;
			gap: 0 1rem;

			&-item {
				display: flex;
				align-items: center;
				gap: 0 .5rem;

				&-icon {
					line-height: normal;
				}

				&-title {
					font-family: $headings-font-family;
					font-size: $small-font-size;
					font-weight: bold;
				}
			}
		}

		.card-buttons {
			.card-btn {
				display: block;
				text-align: center;
			}
		}
	}

	.list-icons {
		display: flex;
		align-items: center;
		gap: 1ch;

		.list-item {
			padding: 0;
			font-size: $small-font-size;

			.icon {
				display: none;
			}

			&:not(:last-child) {
				.label {
					&::after {
						content: '|';
						margin-left: 3px;
					}
				}
			}
		}
	}

	.vakanz-period-tag {
		@extend .vakanz-period;

		&-content {
			position: relative;
		} 
	}
}

// tile-card
.tile-card {
	border: none;
	border-radius: 15px;

	.card-img-overlay {
		top: auto;
		padding: 40px 20px 30px 20px;
		border-radius: 0 0 15px 15px;
		background: linear-gradient(0deg, rgba($black, 0.8) 0%, rgba($black, 0) 100%);

		.card-caption {
			text-shadow: none;

			.card-title {
				margin-bottom: 0;
				@include font-size($h5-font-size);

				&:hover {
					color: $white;
				}
			}

			.card-subtitle,
			.card-description {
				display: none
			}
		}
	}
}

// usps-list
div.usps-list {
	font-size: $small-font-size;
	ul {
		align-items: center;
		margin: 0 -15px;

		li {
			flex-grow: 1;
			margin: 5px 15px;
			line-height: 20px;
			font-weight: 400;

			&::before {
				content: '\f00c';
				margin-right: 10px;
				color: $orange;
				font-size: $h6-font-size;
				font-weight: 400;
				font-family: $font-awesome;
			}

			a {
				color: $black;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

// text-large
.text-large {
	font-size: $h6-font-size;
	
	@include media-breakpoint-up(xl) {
		font-size: $h5-font-size;
	}
}

// text-special
.text-special {
	margin-bottom: 0;
	font-size: 36px;
	line-height: 1;
	font-family: $font-family-special;
}

.text-special-with-arrow {
	@extend .text-special;

	display: inline-flex;
	align-items: flex-end;

	&::after {
		content: url('/images/arrow-right-down.svg');
		margin-left: 10px;
		line-height: 0.75;
	}
}

// owl-nav
.owl-carousel {
	&.slider {
		.owl-nav {
			z-index: 100;
			position: absolute;
			display: flex;
			top: -60px;
			right: 0;

			.owl-prev,
			.owl-next {
				position: static;
				transform: none;
				width: 22px;
				color: $blue;
				font-size: $h6-font-size;
				text-shadow: none;
			}

			.owl-prev:hover {
				transform: translateX(-3px);
			}

			.owl-next:hover {
				transform: translateX(3px);
			}
		}
	}
}

// link-slider
div.link-slider {
	margin-right: 60px;
}

// gallery
.gallery,
.gallery-horizontal {
	.gallery-item {
		margin: 0 0 8px 0;
		padding: 0 4px;
		.gallery-link {
			border-radius: 10px;
		}
	}

	.gallery-label {
		top: 10px;
		left: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $white;
		padding: 10px;
		background-color: $orange;
		border-radius: 100%;
		font-size: $font-size-base;
		font-weight: 400;
		aspect-ratio: 1;
	}
}
