// footer
.footer {

	font-size: $small-font-size;

	a {
		font-family: $font-family-base;
		font-weight: 400;
	}

	// footer-usps-section
	.footer-usps-section {
		padding: 35px 0;
		background-color: $blue-light;
	}

	// footer-follow-section
	.footer-follow-section {
		padding: 43px 0;
		background-color: $blue;
		color: $white;

		// container
		.container {
			.container-holder {
				align-items: center;

				.column {
					@include media-breakpoint-down(lg) {
						@include make-col(12);
					}

					&.one {

						// footer-text
						.footer-text {
							p {
								margin-bottom: 0;

								&:first-of-type {
									@include font-size($h1-font-size);
									line-height: 50px;
									font-family: $headings-font-family;
									font-weight: 500;
								}
							}
						}
					}

					&.two {
						// footer-text
						.footer-text {
							p {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}

		// a
		a:not(.btn) {
			color: $white;

			&:hover {
				text-decoration: underline;
			}
		}

		// footer-logolink
		.footer-logolink {
			ul {
				@include media-breakpoint-up(lg) {
					justify-content: flex-end;
				}

				li {
					font-size: $h5-font-size;

					+li {
						margin-left: 10px;
					}

					a {
						color: $white;

						&:hover {
							color: $black;
						}
					}
				}
			}
		}
	}

	// footer-contact-section
	.footer-contact-section {
		padding-top: 50px;

		// container
		.container {
			.container-holder {
				.column {
					@include media-breakpoint-down(sm) {
						@include make-col(6);
					}
				}
			}
		}

		// footer-text
		.footer-text {
			h5 {
				font-weight: 400;

				a {
					display: flex;
					align-items: center;
					color: $black;
					text-decoration: none;
					font-weight: 400;
					font-family: $headings-font-family;

					&::before {
						margin-right: 10px;
						color: $blue;
						font-size: $font-size-base;
						font-weight: 400;
						font-family: $font-awesome;
					}

					&[href^='tel:'] {
						&::before {
							content: '\f095';
						}
					}

					&[href^='mailto:'] {
						&::before {
							content: '\f0e0';
						}
					}

					&[href^='/'] {
						&::before {
							content: '\f05a';
						}
					}

					&:hover {
						color: $blue;
					}
				}
			}

			p {
				font-weight: 400;
			}
		}
	}

	// footer-sitelinks-section
	.footer-sitelinks-section {
		padding-bottom: 50px;

		// container
		.container {
			&::before {
				content: '';
				display: block;
				margin: 30px 0 50px 0;
				border-bottom: 1px solid rgba($black, 0.3);
			}

			.container-holder {
				.column {
					@include media-breakpoint-down(sm) {
						@include make-col(6);
					}
				}
			}
		}

		// footer-text
		.footer-text {
			h6 {
				margin-bottom: 1rem;
				font-weight: 700;

				a {
					color: $black;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}

			ul {
				margin: 0 0 2rem 0;
				padding: 0;
				list-style: none;

				li {
					a {
						display: inline-block;
						padding: 0.25em 0;
						color: $black;
						text-decoration: none;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}

	.footer-reviews-section {

		.container {
			.container-holder {
				> .column {
					justify-content: center;
					align-items: center;

					@include media-breakpoint-up(sm) {
						flex-direction: row;
					}
				}
			}
		}

		.reviews-overview-average {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: .5rem;
			margin-right: 5px;
		}
	}

	// footer-reviews-section
	// .footer-reviews-section {}

	// footer-copyright-section
	.footer-copyright-section {
		margin-top: 15px;
		padding-bottom: 50px;

		// container
		.container {
			&::before {
				content: '';
				display: block;
				margin: 15px 0;
				border-bottom: 1px solid rgba($black, 0.3);
			}
		}

		// footer-text
		.footer-text {
			ul {
				display: flex;
				justify-content: center;
				margin: 0;
				padding: 0;
				list-style: none;

				@include media-breakpoint-down(sm) {
					flex-direction: column;
					align-items: center;
				}

				li {
					font-size: $small-font-size;

					@include media-breakpoint-up(md) {
						+li {
							&::before {
								content: '-';
								margin: 0 15px
							}
						}
					}

					a {
						color: $black;
						text-decoration: none;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}
