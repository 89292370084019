@mixin slider_header_navs {
    .container-two-columns {
        margin-bottom: 1rem;

        .container-holder {
            align-items: flex-end;

            .column {
                &.two {
                    align-items: flex-end;
                }
            }
        }

        .wysiwyg {
            h1,
            h2 {
                margin-bottom: 0;
            }
        }
    }
}