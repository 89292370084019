#location-tabs {
    &.sticky-navbar {
        width: 100%;
        z-index: 99;
        box-shadow: $shadow;

        &.sticky-navbar-end {
            box-shadow: none;
        }
    }
}

.tabs {
    flex: 1;
    display: flex;
    align-items: center;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    font-size: $small-font-size;
    position: relative;
    overflow: hidden;
    scrollbar-width: none;

    &::after,
    &::before {
        content: '';
        pointer-events: none;
        position: absolute;
        top: 1px;
        height: 70px;
        width: 50px;
        opacity: 0;
        z-index: 1;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    &::after {
        right: 0;
        background-image: linear-gradient(90deg, rgba($white, 0), rgba($body-bg, 1));
    }

    &::before {
        left: 0;
        background-image: linear-gradient(-90deg, rgba($white, 0), rgba($body-bg, 1));
    }

    &.scrollable {
        &.to-left {
            &::after {
                opacity: 1;
            }
        }
        &.to-right {
            &::before {
                opacity: 1;
            }
        }
    }

    .tabs-list {
        flex: 1;
        list-style: none;
        padding: 0;
        margin: 0 -1rem;
        display: flex;
        align-items: center;
        scrollbar-width: none;
        
        @include media-breakpoint-down(md) {
            overflow-y: hidden;
            overflow-x: auto;
            &::-webkit-scrollbar {
                width: 0;
            }
        }

        .tab {
            padding: 1.5rem 1rem;
            white-space: nowrap;

            a {
                color: $headings-color;
            }

            &.active {
                border-bottom: 2px solid $headings-color;
                a {
                    font-weight: bold;
                    text-decoration: none;
                }
            }
        }
    }

}
.btn-back-holder {
    white-space: nowrap;
    .btn-back {
        @extend .mb-0;
        color: $black;

        &::before {
            display: none;
        }
    }
}