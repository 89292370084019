.banner-gallery {
    overflow: hidden;
    margin-bottom: -8px;

    // @include media-breakpoint-up(xl) {
    //     padding-left: 25px;
    //     padding-right: 25px;
    // }

    .gallery-horizontal {

        .large-item {
            .gallery-link {
                &::after {
                    display: none;

                    @include media-breakpoint-up(lg) {
                        display: block;
                    }
                }
            }
        }

        .gallery-item {
            &.first-item, 
            &.first-item + .gallery-item {
                .gallery-link {
                    &::after {
                        display: none;
                    }
                }
            }
        }

        .gallery-link {
            border-radius: 0;
            height: 100%;

            &::after {
                content: '';
                background-image: linear-gradient(rgba($black, 0), rgba($black, 0.8));
                position: absolute;
                inset: auto 0 0;
                height: 100px;
                
                @include media-breakpoint-up(lg) {
                    height: 150px;
                }
            }

            .gallery-img {
                height: 100%;
            }

            .gallery-caption {
                border: 1px solid $white;
                border-radius: 99px;
                width: max-content;
                top: auto;
                left: auto;
                bottom: 24px;
                right: 30px;
                line-height: normal;
                background-color: unset;
                font-size: $small-font-size;
                padding: 10px 20px;
                font-weight: 400;

                &::before {
                    content: '\f8c4';
                    font-family: $font-awesome;
                    margin-right: .5rem;
                }

                &::after {
                    content: 'foto\'s';
                    margin-left: .5ch;
                }
            }
        }
    }
}