.actions-holder {
    padding: 1.5rem 0;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .actions {
        align-self: flex-end;

        .actions-item {
            display: flex;
            align-items: center;
        }

        a {
            color: $link-color;
            font-weight: 500;
            border: none;
            padding-left: 0.75rem;
            padding-right: 0.75rem;

            &:hover {
                background-color: $light;
            }

            .actions-item-icon {
                margin-right: 8px;
            }

            .actions-item-title {
                display: block;
            }
        }

        .share-accommodation {
			position: relative;
		}
    }
}