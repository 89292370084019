.faq-overview {
	margin-top: 1rem;
	> .faq-category {
		margin: 0;
		border-top: 1px solid $black;
		
		&:last-child {
			border-bottom: 1px solid $black;
		}
	}

	.faq-category {
		margin: 0;

		.faq-title {
			padding: 10px 50px 10px 0;

			.faq-icon {
				color: $black;

				.fa-chevron-up::before {
					content: "\f068";
				}
			}

			&:hover {
				h2,
				.faq-icon {
					color: $primary;
				}
			}
		}
		.faq-items {
			.faq-item {
				&.active {
					.faq-icon {
						.fa::before {
							content: "\f068";
						}
					}
				}

				&.active,
				&:hover {
					.faq-question {
						h3 {
							color: $primary;
						}
					}

					.faq-icon {
						color: $primary;
					}
				}

				&:not(:last-child) {
					border-bottom: 1px solid $black;
				}

				.faq-question {
					border: none;
					padding: 10px 50px 10px 0;
					margin: 0;

					h3 {
						color: $black;
						font-size: 18px;
                        transition: .2s ease;
					}

					&:hover {
						.faq-icon {
							color: $primary;
						}
					}
				}

				.faq-answer {
					padding: 0;
					border: none;
					margin: 0;

					a {
						color: $primary;
						&:hover {
							color: $primary;
						}
					}
				}
			}
		}
	}

	.faq-icon {
		transition: .2s ease;
		right: 0;
		left: unset;
		font-size: 18px;
		height: 100%;
		display: flex !important;
		justify-content: flex-end;
		align-items: center;
		color: $black;

		.fa::before {
			content: "\f067";
		}
	}
}
