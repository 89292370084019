// Eyecatcher
&.location-detail {
	.eyecatcher {
		.location-head {
			background-image: linear-gradient(rgba($black, 0), rgba($black, 0.8));
			padding: 3rem 0 1.5rem;
			width: 100%;
			position: absolute;
			bottom: 0;
			z-index: 1;
			display: flex;
			align-items: center;

			.container-fluid {
				flex: 1;
				display: flex;
				align-items: flex-end;
				justify-content: space-between;

				@include media-breakpoint-down(sm) {
					flex-direction: column;
					row-gap: 10px;
				}

				h1 {
					margin: 0;
					color: $white;
				}

				.location-images {
					border: 1px solid $white;
					border-radius: 99px;
					line-height: normal;
					display: flex;
					align-items: center;
					overflow: hidden;
					flex-shrink: 0;

					.gallery-link {
						flex: 1;
						color: $white;
						padding: 12px 20px;
						white-space: nowrap;
						text-decoration: none;

						i {
							margin-right: 0.5rem;
						}

						&:hover {
							background-color: $light;
							color: $text-dark;
						}

						&:not(:last-child) {
							border-right: 1px solid $white;
						}
					}
				}
			}
		}

		// eyecatcher
		&,
		& .owl-carousel .item {
			height: 100vh !important;
			max-height: 640px;
		}

		.owl-carousel {
			.item {

				&::before {
					display: none;
				}
			}

		}
	}
}

&.location-accommodation-detail {
	.eyecatcher {
		display: none;
	}

	#location-tabs {
		.container-one-column .container-holder > .column.default {
			flex-direction: row;
			align-items: center;
		}
	}	
}

// layout
&.location-detail {
	.mini-sab {
		display: none;
	}

	.concept-description {
		ul {
			@extend .custom-list;
		}
	}

	section {
		&.park-intro-section,
		&.park-accommodations-section,
		&.park-map-section,
		&.park-omgeving-section,
		&.park-gallery-section,
		&.park-blog-section,
		&.park-reviews-section {
			padding-bottom: 3.5rem;
		}
	}

	.characteristics {
		margin: 3.5rem 0 0;
	}

	.location-service-description {
		margin-bottom: 2rem;

		@include media-breakpoint-up(lg) {
			@include make-col(9);
			flex: none;
		}
	}

	.park-intro-section {
		#parkfaciliteiten {
			h3.h6 {
				font-weight: 700;
			}
		}

		.collapsable-content:not(.active) {
			.toggle-collapse {
				margin-top: 8px;
			}
		}
	}

	.park-accommodations-section {
		@include media-breakpoint-up(sm) {
			margin-top: 3.5rem;
		}

		.vakanz-period {
			@include media-breakpoint-up(sm) {
				max-width: 640px;
				margin-bottom: 1rem;
				margin-top: -2rem;
			}
			
			// @include media-breakpoint-up(lg) {
			// 	margin-right: 100%;
			// 	white-space: nowrap;
			// }
		}
	}

	.park-map-section {
		overflow: hidden;

		.owl-carousel {
			border-radius: 15px;
			overflow: hidden;
		}

		.owl-carousel,
		.owl-carousel .item {
			height: 100vh !important;
			max-height: 320px;

			@include media-breakpoint-up(lg) {
				max-height: 570px;
			}
		}
	}

	.park-omgeving-section {
		@include slider_header_navs;

        .owl-carousel.slider  {

			.owl-stage-outer {
				padding: 20px 0;
			}

			.card {
				&.card-overlay {
					border: none;
					border-radius: 20px;
					transition: 200ms;

					&:hover {
						box-shadow: 0 16px 18px -10px rgba($black, 0.5);
						transform: translateY(-5px) scale(1.015);
					}
				}

				.card-img-overlay {
					height: 120px;
					top: auto;
					padding: 16px 20px;
					background: linear-gradient(0deg, rgba($black, 0.8) 0%, rgba($black, 0) 100%);
					display: flex;
				}

				.card-caption {
					margin-top: auto;

					.card-subtitle,
					.card-description {
						display: none;
					}

					.card-title {
						@include font-size($h5-font-size);
						margin: 0;
					}
				}
			}
			.owl-nav {
				top: -45px;
			}
		}

		.show-all {
			position: absolute;
			top: -43px;
			right: 60px;
			padding: 0;
		}
	}

	.park-gallery-section {

		@include slider_header_navs;

		.slider-container {
			padding: 0 15px;
		}

		.column > .album-location-overview {
			margin-left: -15px;
			margin-right: -15px;
			margin-top: 14px;

			@include media-breakpoint-up(lg) {
				margin-top: 40px;
			}
		}

		.image-collection-photoalbum {
			margin: 0 !important;
			display: block;

			.owl-stage-outer {
				padding: 20px 10px;
			}

			.owl-stage {
				display: flex;
			}

			.owl-nav {
				@include media-breakpoint-up(lg) {
					top: -85px;
				}
			}

			.owl-item {
				opacity: 0;
				transition: .3s;

				&:nth-child(2n+1) {
					padding-top: 5rem;
				}
				&.active {
					opacity: 1;
				}
			}

			.image-collection-album {
				max-width: 100% !important;
				padding: 0 !important;
				margin: 0 !important;
				border-radius: 20px;
				transition: 200ms;

				&:hover {
					box-shadow: 0 16px 18px -10px rgba($black, 0.5);
					transform: translateY(-5px) scale(1.015);
				}

				h3 {
					@include font-size($h5-font-size);
					color: $white;
					position: absolute;
					bottom: 0;
					z-index: 1;
					margin: 0;
					padding: 16px 20px;
				}

				span.gallery-link {
					color: $white;
					position: absolute;
					top: 0;
					right: 0;
					z-index: 1;
					margin: 0;
					padding: 16px 20px;
					pointer-events: none;
				}
			}
		}

		.gallery {
			border-radius: 20px;
			overflow: hidden;

			&::after {
				content: '';
				position: absolute;
				display: block;
				width: 100%;
				height: 120px;
				top: auto;
				bottom: 0;
				background: linear-gradient(0deg, rgba($black, 0.8), rgba($black, 0));
				display: flex;
				border-radius: 0 0 20px 20px;
			}

			.gallery-item {
				padding: 0;
				margin: 0;

				&:not(.large-item) {
					display: none !important;
				}
			}

			.gallery-img {
				aspect-ratio: 1;
			}
		}

	}

	.park-blog-section {
		@include slider_header_navs;

		padding-top: 3.5rem;

		.owl-carousel.slider {
			.owl-item {
				padding: 4px;
			}

			.owl-nav {
				top: -45px;
			}
		}

		.card {
			@extend .default-card !optional;

			.card-caption {
				.card-subtitle {
					display: none;
				}
			}

			.card-buttons {
				display: none;
			}
		}
	}

	.park-reviews-section {

		.review-create {
			margin-top: 1rem;
		}

		.reviews-overview {

			+ .review-create {
				margin-top: 0;
			}

			&.owl-carousel.slider {
				.owl-stage-outer {
					padding: 4px 0 16px;
				}

				.owl-nav {
					top: -47px;
				}
			}

			.item {
				margin-top: 0;
				padding: 4px
			}
		}
	}

	.park-omgeving-section,
	.park-gallery-section,
	.park-blog-section {
		.owl-carousel {
			.owl-nav {
				@extend .d-flex;
			}
		}
	}
}

&.location-accommodation-detail {

	.content-section {
		margin-top: 0;

		h1 {
			@include font-size($h2-font-size);
			margin-bottom: 20px;
		}

		#description + p.h6 {
			font-weight: 700;
		}
	}

	.info {
		margin-top: 0;
	}

	.highlight-block {
		.title {
			@include font-size($h6-font-size);
			font-family: $headings-font-family;
			font-weight: $headings-font-weight;
			margin-bottom: 0;
		}

		.btn-link {
			margin: 0 0 1rem 0;
		}
	}

	.accordion-list-item-header {
		button {
			justify-content: flex-start;
		}
	}

	#availability {
		margin-top: 4rem;

		.widget-title {
			@include font-size($h2-font-size);
		}
	}
}

&.location-detail {
	.layers-map-placeholder {
		background: url("/images/placeholder.jpg");
		background-size: cover;
		height: 500px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
