// mini-sab
.mini-sab {
	display: none;
	.container {
		position: relative;
		display: flex;
		justify-content: center;

		.container-holder {
			z-index: 100;
			position: relative;

			@include media-breakpoint-down(lg) {
				margin-top: -30px;
			}

			@include media-breakpoint-up(xl) {
				// position: absolute;
				left: auto;
				right: auto;
				bottom: 30px;
			}
		}
	}

	.toggle-mini-sab-wrapper {
		padding: 1rem 1.5rem;
		background-color: #FFF;
		position: relative;
		border-radius: 6px;
		
		.btn-toggle-mini-sab {
			height: 48px;
			border-radius: 6px !important;
		}
	}
}

// home
&.home {
	.mini-sab {
		display: block;
		.container {
			.container-holder {
				margin-top: -150px;

				@include media-breakpoint-up(md) {
					margin-top: -260px;
					margin-bottom: auto;
				}

				@include media-breakpoint-up(xl) {
					bottom: 100px;
					position: absolute;
				}
			}
		}
	}
}

// not home
&:not(.home) {
	.mini-sab {
		@include media-breakpoint-down(lg) {
			background-color: $blue-light;
		}
	}
}
