.highlight-block-holder {
    &.sticky {
        z-index: 1;
    }
}

.highlight-block {
    box-shadow: 0px 0px 10px 0px rgba($black, 0.4);
    padding: 1.5rem 2rem;
    border-radius: 1rem;
    max-width: 370px;
    margin: 0 auto;
    font-size: $small-font-size;

    @include media-breakpoint-down(md) {
        margin-top: 2rem;
    }

    .subtitle {
        font-size: $small-font-size;
        font-weight: 300;
        margin-bottom: 10px;
    }

    .title {
        @include font-size($h4-font-size);
    }

    .short-description {
        ul {
            @extend .custom-list;
        }
    }

    .btn-primary {
        display: block;
        margin-top: 2rem;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        border-radius: 6px;
    }

    .btn-link {
        font-family: $headings-font-family;
        font-weight: 700;
        padding: 0;
        display: block;
        width: max-content;
        margin-left: auto;
        margin-top: 1rem;
    }

    .list {
        display: flex;
        gap: 1ch;
        margin-bottom: .5rem;

        .list-item {
            font-size: $small-font-size;
            font-weight: 300;

            .icon {
                display: none;
            }

            .title {
                margin-right: .5ch;
                font-family: $font-family-base;
                font-size: $small-font-size;
                font-weight: 300;
            }

            &:not(:last-child) {
                &::after {
                    content: '|';
                    margin-left: 1ch;
                }
            }
        }
    }

    .vakanz-period {
        margin-bottom: 1rem;
    }
}