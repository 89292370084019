// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 80vh;
	min-height: 350px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: calc(100vh - 90px);
		min-height: 540px;
	}
}


// eyecatcher
.eyecatcher {
	position: relative;

	// owl-carousel
	.owl-carousel {
		.item {
			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 50%;
				background: linear-gradient(0deg, rgba($black, 0) 0%, rgba($black, 0.5) 100%);
			}

			.owl-caption-holder {
				z-index: 100;

				.owl-container {
					max-width: 720px;

					.owl-caption {
						color: $white;
						text-shadow: none;

						.owl-title {
							font-size: 40px;
							font-weight: 500;
							line-height: 1;

							@include media-breakpoint-up(xl) {
								font-size: 64px;
							}
						}
					}
				}
			}
		}
	}

	// button-view-on-map
	div.button-view-on-map {
		z-index: 100;
		position: absolute;
		left: 0;
		right: 0;
		bottom: -20px;
		display: flex;
		justify-content: center;

		a {
			@extend .btn;
			@extend .btn-primary;
			padding-left: 1.25rem !important;
			padding-right: 1.25rem !important;
			border-radius: 25px !important;
			line-height: 36px !important;

			i {
				margin-right: 10px;
				font-size: 20px;
			}
		}
	}
}

&.home {
	.eyecatcher {
		.owl-nav,
		.owl-dots {
			display: none;
		}
	}
}

// owl-carousel
.owl-carousel:not(.slider) {
	.item {
		position: relative;

		img {
			width: auto;
			max-width: none;
			height: 100%;
		}

		.owl-caption-holder {
			z-index: 100;
			position: absolute;
			inset: 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

.owl-carousel:not(.eyecatcher-owl-carousel) .item {
	padding: 0;

	picture {
		align-self: stretch;
	}
}