&.vacancy-vacancy-overview {
    .vacancy-filter {
        max-width: max-content;
        margin: 0 0 1rem auto;
    }

    .vacancy-overview {
        .grid-items {
            justify-content: flex-start;
        }
    }
}

&.vacancy-category {

    .vacancy-category-overview {
        .grid-items {
            justify-content: flex-start;
            padding-bottom: 30px;
        }
    }
}

&.vacancy-vacancy-detail {
    .vacancy-filter {
        display: none;
    }

    .title {
        h1 {
            margin-bottom: 0;
        }

        h2 {
            margin-top: 1rem;
        }
    }

    .date {
        font-family: $headings-font-family;
        font-weight: 400;
        font-size: $small-font-size;
    }
}