// Review Form
.page-block.review-create {
    .review-form {
        form {
            @extend .form;
        }
        border-radius: 15px;
        margin-bottom: 1.5rem;
    
        .form-control {
            &#review-comment {
                height: 12.5rem; // 200px
            }
        }
    }

    .js-review-complete {
        p {
            margin: 0;
        }
    }
}

// Review list
.reviews-overview {
    margin: 1rem 0 0;
    .item {
        .review {
            padding: 1rem; // 30px
            text-align: left;
            border-radius: 10px;
            border: 1px solid rgba($black, 0.15);
            transition: 200ms;
            flex: 1;

            .review-header {
                display: flex;
                align-items: center;
                margin-bottom: 1rem;
                .review-icon {
                    background-color: $white;
                    width: 2rem;
                    height: 2rem;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 10px;

                    i {
                        font-size: 1.125rem;
                        width: 1.125rem;
                        height: 1.125rem;
                        line-height: normal;
                    }
                }

                .review-details {
                    .reviewer {
                        font-size: 1.125rem; // 18px
                        font-weight: bold;
                        color: $primary;
                    }
                }
            }

            // rating stars
            .rating {
                margin-bottom: 10px;
                font-size: 1rem;
            }

            .date {
                color: $secondary;
                font-size: 12px;
                margin-top: 10px;
            }
        }

        &:hover {
            .review {
                box-shadow: 0 16px 18px -10px rgba($black, 0.25);
                transform: translateY(-5px) scale(1.015);
            }
        }
    }

    &:not(.slider) {
        margin: 0;
        
        column-count: 1;
        
        @include media-breakpoint-up(md) {
            column-count: 2;
        }
        
        @include media-breakpoint-up(lg) {
            column-count: 3;
        }

        .item {
            display: inline-block;
            width: 100%;
        }
    }
}