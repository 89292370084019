.vakanz-period {
    margin-top: 2.25rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-weight: 700;
    font-size: 15px;
    
    .text-special {
        position: absolute;
        white-space: nowrap;
        font-size: 24px;
        line-height: 30px;
        transform: translate(-56px, -32px) rotateZ(-4deg);
        color: $secondary;
        font-weight: 400;

        &::after {
            transform: translate(-5px, 6px) rotate(4deg);
        }
    }
}