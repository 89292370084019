// new base file - january 2022

#TommyBookingSupport { font-size: 16px;}

// colors
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a,
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a:focus,
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a:hover {
	background-color: $primary;
	color: $white;
}
#TommyBookingSupport .tbs-btn-info {
  background-color: $secondary;
  border-color: $secondary;
  color: $white;
}
#TommyBookingSupport .tbs-btn-info:hover {
  background-color: $primary;
  border-color: $primary;
  color: $white;
}
#TommyBookingSupport .tbs-btn-success {
  background-color: $secondary;
  border-color: $secondary;
  color: $white;
}
#TommyBookingSupport .tbs-btn-success:hover {
  background-color: $primary;
  border-color: $primary;
  color: $white;
}
#TommyBookingSupport .tbs-btn-info,
#TommyBookingSupport .tbs-btn-success {
  transition: 0.5s;
}
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-selected {
  background-color: $primary !important;
}

// manually include tommy base styling
// source: https://api.tommybookingsupport.com/widgets/zoekenboek/css/default.css
#TommyBookingSupport .tbs-container-fluid { padding: 0; }
#TommyBookingSupport .tbs-navbar-collapse { padding: 5px 0; }
#TommyBookingSupport .tbs-navbar-nav>li>a { padding-top: 10px; padding-bottom: 10px; }

#TommyBookingSupport .tbs-navbar { 
	min-height: auto; 
	overflow: hidden;
	border: none;
	background-color: unset;
	box-shadow: 0 0 10px rgba(#000, .4); 
}

#TommyBookingSupport .tbs-navbar .tbs-active a {
	background-color: unset !important;
	color: $primary !important;
}

#TommyBookingSupport * a { text-decoration: none; }
#TommyBookingSupport .tbs-legenda { background: none; border: none; display: none; }

#TommyBookingSupport .tbs-row.tbs_persoonscategorien,
#TommyBookingSupport .tbs-row.tbs_accommodaties,
#TommyBookingSupport .tbs-row.tbs_kalenders,
#TommyBookingSupport .tbs-container-fluid.tbs_kassabon,
#TommyBookingSupport .tbs-row.tbs_artikelen,
#TommyBookingSupport .tbs-row.tbs_persoon,
#TommyBookingSupport .tbs-row .tbs-page-overzicht > .tbs-container-fluid > .tbs-row:nth-child(2)  {
	padding: 0;
	margin: 20px 0;
	background-color: #FFF; // custom
	border: 1px solid #FFF; // custom
	border-radius: 5px;
	font-size: 14px;
}

#TommyBookingSupport .tbs-row.tbs_persoonscategorien label {
	font-family: $headings-font-family;
	font-weight: $headings-font-weight;
	font-size: 28px;
}

#TommyBookingSupport .tbs-row.tbs_kalenders {
	border-radius: 0;
	border-bottom: 1px solid #DDD;
}

#TommyBookingSupport .tbs-container-fluid.tbs_kassabon {
	margin: 0; 
	padding: 20px;
	box-shadow: 0 0 10px rgba(#000, .4);
	border-radius: 10px;
	font-size: 14px;
	border: none;
}

#TommyBookingSupport .tbs_overlay-mask { border-radius: 5px; }

#TommyBookingSupport .tbs-calendar { margin: 20px 0; }
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-beschikbaar { 
	cursor: pointer; 
	transition: all 0.5s ease; 
	border: 1px solid #000; 
	color: #000; 
	font-weight: 700;
}
#TommyBookingSupport .tbs-calendar .tbs-day::before,
#TommyBookingSupport .tbs-calendar .tbs-day::after {
	display: none;
}
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-beschikbaar:hover { opacity: 0.8; }
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-nietbeschikbaar { cursor: not-allowed; background-color: unset; color: #c0c4cc; }
#TommyBookingSupport .tbs-calendar table > thead > tr > th { border: none; text-transform: capitalize }
#TommyBookingSupport .tbs-calendar table > thead > tr > th.tbs-month { font-size: 16px; }
#TommyBookingSupport .tbs-calendar table thead > tr > th.tbs-translate { font-weight: 400; font-size: 0.85em; }
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-selected { color: #fff; border-color: $primary; }
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-selected:not(.tbs-beschikbaar) { 
	border-color: #d6e5ee; 
	background-color: #d6e5ee !important; 
	color: #000;
}
#TommyBookingSupport .tbs-calendar .tbs-day { border-radius: 99px; background-color: unset; }
#TommyBookingSupport .tbs-calendar .tbs-day div,
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-selected div { 
	font-size: 14px; 
	color: inherit !important; 
	text-decoration: none;
}

#TommyBookingSupport .tbs-calendar .tbs-day.tbs-bezet { background:unset ; color:#c0c4cc }

#TommyBookingSupport .tbs-row h2 { font-size: 24px; }  // custom
#TommyBookingSupport .tbs-row h4 { color: $body-color; font-size: $font-size-base; font-weight: $font-weight-base; font-family: $font-family-base; } // custom
#TommyBookingSupport .tbs-btn-block+.tbs-btn-block { margin: 0; }
#TommyBookingSupport .tbs_overzicht .tbs-row h2 { margin-bottom: 15px; }

#TommyBookingSupport .tbs_overzicht .tbs-specificaties .tbs-row { margin-top: 15px; }

#TommyBookingSupport .tbs-alert-danger { color: #E91E63; background-color: rgba(233, 30, 99, 0.09);  border: none; margin: 0 15px 15px;  padding: 10px 35px 10px 10px; }
#TommyBookingSupport .tbs-has-error .tbs-form-control { border-color: rgb(244, 67, 54); }

/* kassabon */
#TommyBookingSupport .tbs_kassabon .tbs-row { margin-bottom: 10px; }
#TommyBookingSupport .tbs-label { margin-right: 5px; }
#TommyBookingSupport .tbs_kassabon .tbs-hr { border-top: 1px solid #000; margin-left: 0; margin-right: 0;}
#TommyBookingSupport .tbs_kassabon .tbs-hr > div {
	padding: 0;
}
#TommyBookingSupport .tbs_kassabon .tbs-totaal span.tbs-translate { font-weight: 400; }
#TommyBookingSupport .tbs_kassabon .tbs-totaal span#totaal_amount { font-size: 32px; line-height: 50px; color: $primary; }
#TommyBookingSupport .tbs-subtotaal span.tbs-amount { 
	color: $primary; 
	font-size: 16px;
	font-weight: 700;
}

#TommyBookingSupport strong[data-trn-key="__kassabon_subtotaal__"] {
	color: $primary; 
	font-size: 16px;
}

#TommyBookingSupport .tbs_kassabon .tbs_totaal_overzicht .tbs-totaal {
	background-color: #FFF;
	color: $black;
}

#TommyBookingSupport .tbs_kassabon .tbs_totaal_overzicht .tbs_kassabon_toggle {
	color: $primary;
}

#TommyBookingSupport .tbs_kassabon .tbs_totaal_overzicht .tbs_kassabon_toggle .tbs-glyphicon-chevron-left:before {
	content: "\e113";
}

#TommyBookingSupport .tbs_kassabon .tbs_totaal_overzicht .tbs_kassabon_toggle .tbs-glyphicon-chevron-right:before {
	content: "\e114";
}

#TommyBookingSupport .tbs_persoonsgegevens_overzicht > .tbs-row { margin: 0 0 15px 0; }

/* resonsive mobile */
@media (max-width: 767px) {

	#TommyBookingSupport .tbs-calendarPrevious span.tbs-translate,
	#TommyBookingSupport .tbs-calendarNext span.tbs-translate { display: none; }

	#TommyBookingSupport .tbs-container-fluid.tbs_kassabon { margin-top: 20px; }
}

#TommyBookingSupport #tbs_av {
	margin-top: 0;
	margin-bottom: 0;
}

#TommyBookingSupport #tbs_av + label {
	margin-bottom: 0;
}

.tbs-widget-label {
	display: none;
}