// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section+.content-section,
.lead-section+.bundle-detail-section,
.lead-section+.bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// usps-section
// =========================================================================
.usps-section {
	padding: 25px 0;
	background-color: $blue-light;
}

&.home {
	.usps-section {
		padding-top: 35px;
	}
}

// popular-destinations-section
// =========================================================================
.popular-destinations-section {
	padding: 50px 0;

	.container {
		&.container-one-column {
			margin-bottom: 30px;

			// .container-holder {
			// 	max-width: 880px;
			// }
		}

		&.container-two-columns {
			margin-bottom: 30px;

			.container-holder {
				align-items: flex-end;

				.column {
					&.two {
						align-items: flex-end;
					}
				}
			}

			.wysiwyg {

				h1,
				h2 {
					margin-bottom: 0;
				}
			}
		}
	}

	// collection
	.collection {
		.card {
			border: none;
			border-radius: 20px;

			.card-img-overlay {
				top: auto;
				padding: 40px 20px 30px 20px;
				border-radius: 20px;
				background: linear-gradient(0deg, rgba($black, 0.8) 0%, rgba($black, 0) 100%);

				.card-caption {
					text-shadow: none;
					text-align: center;

					.card-title {
						@include font-size($h4-font-size);

						&:hover {
							color: $white;
						}
					}

					.card-subtitle,
					.card-description {
						display: none
					}
				}

				.card-buttons {
					text-align: center;

					.card-btn {
						@extend .btn-primary;
					}
				}
			}
		}
	}
}

// highlight-section
// =========================================================================
.highlight-section {
	padding: 20px 0 50px 0;

	.container-two-columns {
		.container-holder {
			align-items: center;

			.column {
				&.one {
					@include media-breakpoint-up(lg) {
						@include make-col(5);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(5);
						padding-right: 85px !important;
					}
				}

				&.two {
					@include media-breakpoint-up(lg) {
						@include make-col(7);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(7);
						padding-left: 85px !important;
					}
				}
			}

			.wysiwyg {
				a {
					font-family: $headings-font-family;
					font-weight: 700;
				}
			}
		}
	}

	// collection
	.collection {
		&.masonry {
			.card-columns {
				column-count: 2;
				column-gap: 20px;

				@include media-breakpoint-up(lg) {
					column-count: 2;
				}

				@include media-breakpoint-up(xl) {
					column-count: 2;
				}

				.item {
					&:nth-child(3) {
						padding-top: 100px;
					}

					.card {
						display: block;
						margin-bottom: 20px;
					}
				}
			}
		}

		.card {
			@extend .tile-card;
		}
	}
}

// tiles-collection-section
// =========================================================================
.tiles-collection-section {
	padding: 50px 0;
	overflow: hidden;

	// collection
	.collection {
		&.grid {
			.grid-items {
				margin: 0 -5px;

				.item {
					margin-bottom: 10px;
					padding-left: 5px;
					padding-right: 5px;
				}
			}
		}

		&.second {
			&.grid {
				.grid-items {
					.item {
						@include media-breakpoint-up(lg) {
							@include make-col(6);
						}

						@include media-breakpoint-up(xl) {
							@include make-col(6);
						}

						img {
							aspect-ratio: 3 / 2; 
						}
					}
				}
			}
		}

		.card {
			@extend .tile-card;
			border-radius: 0;

			.card-img-overlay {
				border-radius: 0;

				.card-caption {
					text-align: center;

					.card-title {
						@include font-size($h4-font-size);
					}
				}
			}
		}
	}
}

// popular-accommodations-section
// =========================================================================
.popular-accommodations-section {
	padding: 50px 0;
	overflow: hidden;

	// collection
	.collection {
		height: 75vh;
		min-height: 300px;
		max-height: 720px;
		// border-radius: 20px;
		overflow: hidden;

		.item {
			position: relative;
			height: 75vh !important; // overide
			min-height: 300px;
			max-height: 720px;
			padding: unset; // overide
			align-items: flex-end;

			.owl-caption-holder {
				// border-radius: 20px;
				background: linear-gradient(0deg, rgba($black, 0.8) 0%, rgba($black, 0) 100%);
				align-items: flex-end;

				.owl-container {
					max-width: unset;
					padding-left: 0;
					padding-right: 0;

					.owl-caption {
						padding: 20px;
						text-align: left;
						text-shadow: none;
						display: flex;
						flex-wrap: wrap;
						align-items: flex-end;

						@include media-breakpoint-up(lg) {
							padding: 40px 40px 30px;
						}

						.owl-title {
							@include font-size($h4-font-size);
							flex: 0 0 100%;
							margin-bottom: 10px;
							
							@include media-breakpoint-up(lg) {
								margin-bottom: 0;
							}
						}

						.owl-subtitle {
							position: absolute;
							top: 15px;
							right: 15px;
							display: flex;
							align-items: center;
							justify-content: center;
							width: 130px;
							margin-bottom: 0;
							padding: 16px;
							background-color: $orange;
							border-radius: 100%;
							font-size: $h6-font-size;
							font-weight: 700;
							text-align: center;
							font-family: $font-family-base;
							aspect-ratio: 1;
							overflow: hidden;

							@include media-breakpoint-up(lg) {
								top: 26px;
								right: 40px;
							}
						}

						.owl-description {
							flex: 1;
							font-size: $font-size-base;
							font-weight: 300;
							margin-bottom: 0;

							strong {
								font-weight: 700;
							}

							p {
								margin-bottom: 0;
							}
						}

						.owl-btn {
							@extend .btn-primary;
							flex: 0 1 auto;
							max-width: 220px;
							padding-left: 1.75rem;
							padding-right: 1.75rem;
							color: $white;

							@include media-breakpoint-up(sm) {
								flex: 1;
							}
						}
					}
				}
			}
		}

		// owl-nav
		.owl-nav {
			.owl-prev,
			.owl-next {
				font-size: 36px;
				text-shadow: none;
			}

			.owl-prev {
				@include media-breakpoint-up(lg) {
					left: 20px;
				}
			}

			.owl-next {
				@include media-breakpoint-up(lg) {
					right: 20px;
				}
			}
		}
	}
}

// recommended-accommodations-section
// =========================================================================
.recommended-accommodations-section {
	padding: 50px 0;

	// container
	.container {
		&:nth-child(1) {
			margin-bottom: 30px;

			.container-holder {
				align-items: flex-end;

				.column {
					&.two {
						align-items: flex-end;
					}
				}
			}

			.wysiwyg {

				h1,
				h2 {
					margin-bottom: 0;
				}
			}
		}
	}

	// collection
	.collection {
		.item {
			padding: 4px;
		}
		.card {
			@extend .default-card !optional;

			.card-image-caption {
				width: max-content;
				aspect-ratio: unset;
				padding: 10px;
				border-radius: 4px;
				font-size: .875em;
				right: auto;
				top: auto;
				left: 20px;
				bottom: -20px;
				background-color: $orange;
				
				&::after {
					content: "";
					display: inline-block;
					position: absolute;
					left: 14px;
					bottom: -12px;
					z-index: 1;
					border-left: 14px solid $orange;
					border-bottom: 14px solid transparent;
					border-right: 14px solid transparent;
				}
			}

			.card-description-content {
				display: none;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}

// costs-section
// =========================================================================
.costs-section {
	.custom-list {
		width: max-content;

		li {
			gap: 1.25rem;
			display: flex;
		}
	}

	.list-price {
		font-weight: 400;
		margin-left: auto;
	}
}