.collapsable-content {
    .collapse-inner {
        max-height: 250px;
        overflow: hidden;
        // transition: max-height 0.3s ease;
    }

    a.toggle-collapse {
        display: block;
        margin-bottom: 1rem;
        transition: none;

        &::after {
            content: '\f078';
            font-family: $font-awesome;
            font-weight: 400;
            margin-left: 10px;
        }
    }

    &.active {
        .collapse-inner {
            max-height: 100%;
            margin-bottom: 0;
        }

        a.toggle-collapse {
            &::after {
                content: '\f077';
            }
        }
    }
}