.modal {
    .modal-content {
        border-radius: 1.25rem;
    }

    .card-img {
        border-radius: 0.875rem;
        height: 100%;
        object-position: center;
        aspect-ratio: 3/2.5;
        transition: 200ms;

        &:hover {
            transform: scale(1.05);
        } 

        @include media-breakpoint-down(md)  {
            margin-top: 4rem;
        }
    }

    .modal-body {
        padding: 5px;

        @include media-breakpoint-down(md) {
            padding: 0 1rem 1rem;
        }

    }

    .col-lg-6 {
        position: static;

        picture {
            overflow: hidden;
            display: block;
            border-radius: 0.875rem;
        }
    }

    .content-holder {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .content-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0;

        @include media-breakpoint-down(md) {
            position: absolute;
            top: 0;
            left: 1rem;
            right: 1rem;
        }

        .modal-title {
            font-size: 12px;
            font-weight: 300;
        }

        .btn {
            padding-left: 12px;
            padding-right: 12px;
            font-size: 24px;
            color: $primary;
            
            &:hover {
                color: darken($primary, 20%);
            }
        }
    }

    .content-body {
        padding-right: 50px;

        @include media-breakpoint-down(md) {
            padding-top: 1.5rem;
        }

        .content-body-subtitle {
            font-size: $small-font-size;
        }

        p {
            line-height: 1.75;
        }
    }

    .content-footer {
        .btn {
            padding: 5px;
            font-size: 16px;
            color: $primary;

            &:hover {
                color: darken($primary, 20%);
            }
        }
    }
}