// custom-list
.custom-list {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1.5rem;

		&::before {
			content: '\f00c';
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $primary;
			font-weight: 900;
		}
	}
}

// custom-list-extra
.custom-list-extra {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1.5rem;

		&::before {
			content: '\f058';
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $primary;
			font-weight: 400;
		}
	}
}


.kenmerken-list {
	@include make-row();

	li {
		@include make-col-ready();
		@include make-col(6);

		margin-bottom: 10px;

		@include media-breakpoint-up(sm) {
			@include make-col(4);
		}

		.icon {
			color: $primary;
			display: inline-block;
			min-width: 26px;
		}
	}
}

.kenmerken-sub-list {
	.sub-list {
		// column-count: 3;
		@include make-row();
		
		.sub-list-item {
			@include make-col-ready();
			@include make-col(6);

			margin-bottom: 10px;

			@include media-breakpoint-up(sm) {
				@include make-col(4);
			}

			.icon {
				display: inline-block;
				min-width: 26px;
				color: $primary;
			}
		}
	}
}


// platforms-list
.platforms-list {
	padding: 0;
	margin: 0;
	list-style: none;
	position: absolute;
	top: -1rem;
	left: 50%;
	z-index: -1;
	opacity: 0;
	transform: translateX(-50%);
	transition: .5s;

	&-item {
		margin-bottom: 5px;

		&-link {
			display: block;
			text-decoration: none;
			background-color: transparent !important;

			i {
				width: 2rem;
				height: 2rem;
				line-height: 2rem;
				border-radius: 50%;
				text-align: center;
				background-color: $light;
				color: $black;

				&:hover {
					color: $white;
					background-color: $secondary;
				}
			}
		}
	}

	&.open {
		z-index: 999;
		top: 40px;
		opacity: 1;
	}
}