// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$blue: #008bcb;
$blue-light: #f4f9fc;
$orange: #f28b00;

$white: #fff;
$black: #000;

// theme-colors
$primary: $orange;
$secondary: $blue;

// body
$body-bg: $white;
$body-color: $black;

// links
$link-color: $blue;
$link-decoration: none;
$link-hover-color: $orange;

// typography
// @import url('https://fonts.googleapis.com/css2?family=Bilbo+Swash+Caps&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Quicksand:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bilbo+Swash+Caps&family=Quicksand:wght@300..700&family=Roboto:wght@400;500;700&display=swap');

// $font-family-primary: 'Inter', sans-serif;
$font-family-primary: 'Roboto', sans-serif;
$font-family-secondary: 'Quicksand', sans-serif;
$font-family-special: 'Bilbo Swash Caps', cursive;

$font-family-base: $font-family-primary;

$font-size-base: 1rem;
$font-weight-base: 300;
$line-height-base: 1.6;

$h1-font-size: $font-size-base * 2.5; // 40px
$h2-font-size: $font-size-base * 2; // 32px
$h3-font-size: $font-size-base * 1.75; // 28px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.25; // 20px
$h6-font-size: $font-size-base; // 16px

$small-font-size: $font-size-base * 0.875; // 14px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 500;
$headings-color: $black;

// contextual
$light: $blue-light;
$dark: $blue;
$text-light: $white;
$text-dark: $black;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
