// header
.header {
	z-index: 998;
	transition: background-color 0.5s;

	@include media-breakpoint-up(xl) {
		padding-left: 25px;
		padding-right: 25px;
	}

	.container,
	.container-fluid {
		.container-holder {
			>.column {
				align-items: flex-start !important;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		.navbar-toggler {
			width: 40px;
			height: 40px;
			margin-right: 15px;
			padding: 0;
			border: none;
			border-radius: 100%;
			background: $blue;
			color: $white;
			font-size: $h6-font-size;
			transition: 0.5s;

			i {
				line-height: 40px;
				transition: transform 0.5s;
			}

			&[aria-expanded="true"] {
				i {
					transform: rotate(180deg);

					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// logo
	.logo {
		width: 115px;
		margin-right: auto;

		@include media-breakpoint-down(xs) {
			width: 90px;
		}

		a {
			display: block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		transition: .3s;
		// Common styles for both breakpoints
		.navbar-nav {
			.nav-item {
				.nav-link {
					display: flex;
					align-items: center;
					color: $black;
					font-size: $h6-font-size;
					font-family: $headings-font-family;
					font-weight: 700;

					&:hover,
					&[aria-expanded=true] {
						color: $orange !important;
					}

					&.dropdown-toggle {
						&::after {
							content: '\f078';
							margin-left: 8px;
							font-weight: 900;
							font-family: $font-awesome;
							border: none;
							vertical-align: unset;
							transition: .2s;
						}

						&[aria-expanded=true] {
							&::after {
								content: '\f077';
							}
						}
					}
				}

				&.active {
					>.nav-link {
						color: $orange;
					}
				}

				// 1st level dropdown menu
				.dropdown-menu {
					padding: 0;
					border: none;
					border-radius: 0;
					box-shadow: none;
					background: transparent;
					white-space: nowrap;

					&::before,
					&::after {
						display: none;
					}

					.dropdown-item {
						&:hover {
							background-color: unset;
						}
					}

					.nav-item {
						font-size: $h6-font-size;
						line-height: 1.2;

						.nav-link {
							padding-bottom: 0;
							color: $black;
							font-weight: 400;
							font-family: $font-family-base;

							&:hover {
								color: $orange;
							}
						}

						&.dropdown {
							.nav-link {
								font-weight: 700;
								font-family: $headings-font-family;
							}
						}

						&.active {
							> .nav-link {
								color: $orange;
							}
						}

						// 2nd level dropdown menu
						.dropdown-menu {
							display: block !important;
							margin: 0;
							box-shadow: none;
							position: static;
							transform: none !important;
							padding: 0;
							min-width: 200px;

							.nav-item {
								margin: 0;

								.nav-link {
									font-weight: 400;
									font-family: $font-family-base;
									font-size: $font-size-base;
								}
							}
						}
					}
				}
			}
		}

		// Specific styles for lg and below
		@include media-breakpoint-down(lg) {
			order: 5;

			.navbar-nav {
				margin: 15px 0;

				.nav-item {
					.dropdown-menu {
						position: static !important;
						margin: 0 0 0 15px;
						transform: none !important;

						// Ensure nested styles are included
						> .nav-item.dropdown {
							margin-bottom: 1rem;
						}
					}
				}
			}
		}

		// Specific styles for xl and above
		@include media-breakpoint-up(xl) {
			justify-content: center;

			.navbar-nav {
				.nav-item {
					margin: 0 10px;

					.dropdown-menu {
						padding: 0.5rem;
						border-color: $white;

						&.show {
							display: block;
						}

						&:has(.dropdown-menu) {
							&.show {
								display: flex;
								top: 150% !important;
								left: -50% !important;
								width: 100%;
							}
						}
					}
				}
			}
		}
	}


	// quick-icons
	div.quick-icons {
		ul {
			padding: 0 0.5rem 0 1rem;
			border-radius: 20px 0 0 20px;
			background-color: $blue-light;
			
			@media screen and (max-width: 376px) {
				padding: 0 6px 0 6px;
			}

			li {
				a {
					display: block;
					padding: 0 0.5rem;
					color: $black;
					font-size: $h6-font-size;
					line-height: 40px;

					@media screen and (max-width: 376px) {
						font-size: $small-font-size;
					}

					.list-item-title {
						display: none
					}

					&:hover {
						color: $blue;
					}
				}
			}
		}
	}

	// wishlist-info
	div.wishlist-info {
		a {
			display: block;
			padding: 0 0.5rem;
			background-color: $blue-light;
			color: $black;
			font-size: $h6-font-size;
			line-height: 40px;

			&:hover {
				color: $blue;
			}
		}
	}

	// book-button
	div.book-button {
		a {
			@extend .btn;
			@extend .btn-secondary;
			border-top-left-radius: 0 !important;
			border-bottom-left-radius: 0 !important;
		}
	}
}


// header home
&.home {
	.header {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding-bottom: 20px;

		@include media-breakpoint-down(lg) {
			padding-top: 20px;
		}

		@include media-breakpoint-up(xl) {
			padding-top: 50px;
		}

		// menu
		.menu {
			@include media-breakpoint-up(xl) {
				.navbar-nav {
					>.nav-item {
						>.nav-link {
							color: $white;

							&:hover {
								color: $orange;
							}
						}

						&.active {
							>.nav-link {
								color: $orange;
							}
						}
					}
				}
			}
		}

		// quick-icons
		div.quick-icons {
			ul {
				background-color: $white;
			}
		}

		// wishlist-info
		div.wishlist-info {
			a {
				background-color: $white;
			}
		}

		&.sticky {
			position: fixed;
			top: 0;
			padding-top: 20px;

			.container,
			.container-fluid {
				.container-holder {
					>.column {
						align-items: center;
					}
				}
			}
		}

		&.header-active,
		&.sticky {
			background-color: $white;
			box-shadow: 0 4px 10px rgba($black, 0.20);

			// menu
			.menu {
				@include media-breakpoint-up(xl) {
					.navbar-nav {
						>.nav-item {
							>.nav-link {
								color: $black;

								&:hover {
									color: $orange;
								}
							}

							&.active {
								>.nav-link {
									color: $orange;
								}
							}
						}
					}
				}
			}

			// quick-icons
			div.quick-icons {
				ul {
					background-color: $blue-light;
				}
			}

			// wishlist-info
			div.wishlist-info {
				a {
					background-color: $blue-light;
				}
			}
		}
	}


	&.navbar-collapse-active {

		@include media-breakpoint-down(lg) {
			// quick-icons
			div.quick-icons {
				ul {
					background-color: $blue-light;
				}
			}
	
			// wishlist-info
			div.wishlist-info {
				a {
					background-color: $blue-light;
				}
			}
		}
	}

	&.header-active {
		padding-bottom: 20px;
		background-color: $white;
		box-shadow: 0 4px 10px rgba($black, 0.20);

		// menu
		.menu {
			@include media-breakpoint-up(xl) {
				.navbar-nav {
					>.nav-item {
						>.nav-link {
							color: $black;

							&:hover {
								color: $orange;
							}
						}

						&.active {
							>.nav-link {
								color: $orange;
							}
						}
					}
				}
			}
		}
	}
}

// header content
&:not(.home) {
	.header {
		position: sticky;
		top: 0;
		padding-top: 20px;
		padding-bottom: 20px;
		background-color: $white;
		box-shadow: 0 4px 10px rgba($black, 0.20);

		.container,
		.container-fluid {
			.container-holder {
				>.column {
					align-items: center;
				}
			}
		}
	}
}

// navbar-collapse-active
&.navbar-collapse-active {
	.header {
		background-color: $white;
		box-shadow: 0 4px 10px rgba($black, 0.20);
	}
}
